import logo from './logo-white.png';
import './App.css';
import { useEffect, useState } from 'react';
import { Configuration, OpenAIApi } from "openai";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

function App() {
  const [topics, setTopics] = useState(['']);
  const [length, SetLength] = useState(0);
  const [platform, SetPlatform] = useState(undefined);
  const [answer, setAnswer] = useState({

    content: 'No answer'
  }
  )

  const selected = (e) => {
    SetPlatform(e.target.value); // This will also set the platform state if you need it.
  }

  const Length = (e) => {
    SetLength(e.target.value); // This will also set the platform state if you need it.
  }

  const Topic = (e, index) => {
    const newTopics = [...topics];
    newTopics[index] = e.target.value;
    setTopics(newTopics);
  };

  const addTopicField = () => {
    setTopics([...topics, '']);
  };

  const hello = async () => {
    let i = 0;
    setAnswer({ content: 'Loading...' })
    const configuration = new Configuration({
      apiKey: "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",
    });
    const openai = new OpenAIApi(configuration);
    const zip = new JSZip();

    for (const topic of topics) {
      i = i + 1;
      try {
        const completion = await openai.createChatCompletion({
          model: "gpt-3.5-turbo",
          messages: [{ "role": "system", "content": "You are an expert digital marketing copy writer" },
          {
            role: "user",
            content: `Write me a ${platform} blog post about ${topic} and make it ${length} words. include the post title as Title and the post body as Body`
          }],
        });
        zip.file(`${topic}.txt`, completion.data.choices[0].message.content);

      } catch (error) {
        console.error("Error calling OpenAI API:", error);
        setAnswer({ content: error })
      }
      setAnswer({ content: `${i}/${topics.length} Done` })
    }
    setAnswer({ content: 'Success! Download will start soon' })
    zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, "generated-content.zip");
    });
    i = 0;
  };


  const downloadContent = (content, filename) => {
    const blob = new Blob([content], { type: 'text/plain;charset=utf-8' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    URL.revokeObjectURL(link.href);
  };

  useEffect(() => {

  }, [answer])

  return (
    <div className="App-header">
      <img src={logo}
        width='700px'
        height='300'
        className=""
        alt="logo" />
      <h4>Welcome! </h4>
      <p>Please choose a topic you want to generate content about, the length and the paltform </p>
      <p>Then click on "Generate" and when the AI will finish the content will be downloaded as a ZIP file</p>
      <div style={{
        color: 'white'
      }}>
        <p>{answer.content}</p>
      </div>
      <br></br>
      {answer.content !== 'No answer' && answer.content !== 'Error' && answer.content !== 'Complete and will be downloaded soon' ?
        <>
          <button onClick={() => downloadContent(answer.content, `${topics}.txt`)}>Download</button>
          <button onClick={() => {
            setAnswer({ content: 'No answer' })
          }}>Clear</button>
        </>
        : ''}

      {/*console.log(answer.content.split('Post Body:'))*/}
      <div className="input-div">
        <h3>Topic</h3>
        {topics.map((topicValue, index) => (
          <div key={index}>

            <input
              type="text"
              placeholder='Please Enter Your Request'
              value={topicValue}
              onChange={e => Topic(e, index)}
            />
          </div>
        ))}
        <button onClick={addTopicField}>+</button>
        <h3>Length</h3>
        <input type="number" defaultValue={0} onChange={Length} ></input>
        <h3>Platform</h3>
        <select onChange={selected}>
          <option value="LinkedIn">LinkedIn</option>
          <option value="Facebook">Facebook</option>
          <option value="SEO blog">SEO blog</option>
        </select>
      </div>
      <br></br><br></br>
      <button onClick={hello}>Generate</button>
    </div>
  );
}

export default App;
